import React, { MouseEvent, useRef, useState } from 'react';

import { OpenedBet } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Dialog, DialogContent } from '~components/atoms/Modal';
import { ShareLink } from '~components/atoms/ShareLink';
import { Text } from '~components/atoms/Typography';
import { SPORT_BETSLIP_TYPE_CODES } from '~components/molecules/Betslip/constants';
import { getStatusIcon } from '~components/molecules/BetslipHistorySection/historyUtils';
import {
  BetHistoryCellProps,
  BetHistoryFirstCellProps,
} from '~components/molecules/BetslipHistorySection/types';
import { CashOut, CashOutSuccess } from '~components/molecules/CashOut';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setCashOutBet, setCashOutProcessed } from '~store/slices/openBetSlice';
import { getShareBetData } from '~utils/betslip';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { commaSeparatedNumber } from '~utils/numberUtils';

const BetHistoryDateIdOuterCell = ({
  createdAt,
  id,
  status,
}: BetHistoryFirstCellProps) => {
  const StatusIcon = getStatusIcon(status);
  const isActiveBet = status === 1;

  return (
    <Box
      flexRow
      css={{
        maxWidth: '250px',
      }}
    >
      <Box flexRow alignCenter gap={3}>
        <Box flexRow css={{ color: '$textSecondaryOne' }}>
          {StatusIcon && <StatusIcon />}
        </Box>
        <Box flexCol gap={1}>
          <Text level="12-20">{createdAt}</Text>
          <Box flexRow justifyContentStart alignCenter gap={2}>
            <Text level="12-20" ellipsis>
              ID: {id}
            </Text>
            {isActiveBet ? <ShareLink data={getShareBetData(id)} /> : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const DateAndIdCell = ({ row: { original } }: BetHistoryCellProps) => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const { cashOutProcessed } = useAppSelector((state) => state.openBets);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isCashOutDialogOpen, setIsCashOutDialogOpen] = useState(false);
  const { status, availableCashout, createdAt, code, type } = original;

  const formattedDate = formatDateTime(createdAt, TIME_FORMATS.DATE_TIME_12H);

  const handleCashOutClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsCashOutDialogOpen(true);
    dispatch(setCashOutBet(original as unknown as OpenedBet));
  };

  const handleContinue = (e?: MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setIsCashOutDialogOpen(false);
    dispatch(setCashOutBet(null));
    dispatch(setCashOutProcessed(false));
  };

  const toggleDialog = (value: boolean) => {
    if (!value) {
      handleContinue();
    }
  };

  return (
    <Box flexCol>
      <BetHistoryDateIdOuterCell
        createdAt={formattedDate}
        id={code}
        status={status}
      />
      {status === 1 &&
      availableCashout &&
      type !== SPORT_BETSLIP_TYPE_CODES.SYSTEM ? (
        <Button
          onClick={handleCashOutClick}
          css={{
            p: '$1 $2',
            gap: '$1',
            backgroundColor: '$accentSecondaryOne',
            ml: '1.75rem',
            borderRadius: '$4',
          }}
        >
          <Text color="textPrimaryOne" level="11-16" textTransform="capitalize">
            {localized('betslip.cashOut') + ':'}
          </Text>
          <Text level="11-16" color="yellowPrimary">
            {commaSeparatedNumber(availableCashout)}{' '}
            {CURRENCY_SYMBOLS[currency]}
          </Text>
        </Button>
      ) : null}

      <Dialog open={isCashOutDialogOpen} toggleDialog={toggleDialog}>
        <DialogContent hasOverlay top ref={dropdownRef}>
          {cashOutProcessed ? (
            <CashOutSuccess onContinue={handleContinue} />
          ) : (
            <CashOut onCancel={handleContinue} noCloseIcon isBetHistory />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
