import {
  ComponentProps,
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as SelectUI from '@radix-ui/react-select';
import { CSS } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useOuterClick } from '~hooks/useOuterClick';
import { CheckedIcon, ChevronUpIcon } from '~icons';

import {
  StyledBaseSelectTrigger,
  StyledItem,
  StyledItemIndicator,
  StyledSelectContent,
  StyledSelectIcon,
  StyledSelectTriggerContent,
  StyledSelectUIPlaceholder,
  StyledSelectUIValue,
  StyledSelectViewport,
} from './styled.components';

type SelectTriggerProps = ComponentProps<typeof StyledBaseSelectTrigger> & {
  isCombinedWithContent?: boolean;
};

export const SelectTrigger = forwardRef<HTMLButtonElement, SelectTriggerProps>(
  (props, forwardedRef) => {
    return <StyledBaseSelectTrigger {...props} ref={forwardedRef} />;
  },
);

type SelectContentProps = ComponentProps<typeof StyledSelectContent> & {
  isCombinedWithContent?: boolean;
};

export const SelectContent = forwardRef<HTMLDivElement, SelectContentProps>(
  (props, forwardedRef) => {
    return <StyledSelectContent {...props} ref={forwardedRef} />;
  },
);

type SelectViewportProps = ComponentProps<typeof StyledSelectViewport> & {
  isCombinedWithContent?: boolean;
  isNoMaxHeight?: boolean;
  noContentPadding?: boolean;
};

export const SelectViewport = forwardRef<HTMLDivElement, SelectViewportProps>(
  ({ ...props }, forwardedRef) => {
    return <StyledSelectViewport {...props} ref={forwardedRef} />;
  },
);

type SelectItemProps = ComponentProps<typeof SelectUI.Item> & {
  children?: ReactNode;
  width?: number | string | null;
  maxWidth?: number | string | null;
};

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  (props, forwardedRef) => {
    const { children, width, maxWidth, ...restProps } = props;

    return (
      <StyledItem
        {...restProps}
        ref={forwardedRef}
        css={{
          minWidth: width ? width : '100px',
          maxWidth: maxWidth || 'unset',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <SelectUI.ItemText>{children}</SelectUI.ItemText>
        <StyledItemIndicator css={{ left: 'unset', right: '0' }}>
          <CheckedIcon />
        </StyledItemIndicator>
      </StyledItem>
    );
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Option<T = any> {
  value: string;
  label?: string;
  data?: T;
}

export interface FooterAction {
  label: string;
  onClick: () => void;
}

type SelectProps = ComponentProps<typeof SelectUI.Root> & {
  ariaLabel?: string;
  centeredValue?: boolean;
  children?: ReactNode;
  contentCss?: CSS;
  contentWidth?: number | string | null;
  css?: CSS;
  footerAction?: FooterAction;
  icon?: ReactNode;
  iconSize?: number;
  isCombinedWithContent?: boolean;
  isCustomChildren?: boolean;
  isCustomContentWidth?: boolean;
  isOpened?: boolean;
  noContentPadding?: boolean;
  options?: Option[];
  placeholder?: string | ReactNode;
  shouldClose?: boolean;
  value?: string;
  hideLabel?: boolean;
  onChange?: (value: string) => void;
  onClose?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderOption?: (option: any) => JSX.Element;
  disabled?: boolean;
  notPreventClickBackdrop?: boolean;
  noSeparator?: boolean;
};

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      ariaLabel,
      centeredValue = false,
      children,
      contentCss,
      contentWidth,
      css,
      footerAction,
      icon,
      iconSize = 12,
      isCombinedWithContent = false,
      isCustomChildren,
      isCustomContentWidth,
      isOpened = false,
      noContentPadding = false,
      hideLabel,
      options,
      placeholder,
      shouldClose,
      value,
      onChange,
      onClose,
      onOpenChange,
      renderOption,
      disabled,
      notPreventClickBackdrop,
      noSeparator = false,
      ...props
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = useState(isOpened);
    const [selectedValue, setSelectedValue] = useState(value || '');
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { isMobileOrTablet } = useMedia();

    useOuterClick(dropdownRef, () => {
      setIsOpen(false);
    });

    if (!children && !options) {
      throw new Error(
        'The Select component requires either children or options prop.',
      );
    }

    const handleValueChange = (newValue: string) => {
      setSelectedValue(newValue);
      onChange && onChange(newValue);
    };

    const handleOnOpenChange = (open: boolean) => {
      setIsOpen(!isOpen);
      onOpenChange && onOpenChange(open);
    };

    const renderValue = () => {
      const option = options?.find((item) => item.value === value);

      if (option?.label) {
        return option.label;
      } else if (renderOption && option?.data) {
        return renderOption(option.data);
      }

      return placeholder;
    };

    const handleTouch = useCallback((e: TouchEvent) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    }, []);

    useEffect(() => {
      return () => {
        const currentRef = dropdownRef.current;

        if (currentRef) {
          currentRef.removeEventListener('touchend', handleTouch);
          currentRef.removeEventListener('touchstart', handleTouch);
        }
      };
    }, [dropdownRef.current, notPreventClickBackdrop]);

    useEffect(() => {
      setSelectedValue(value || '');
    }, [value]);

    useEffect(() => {
      if (!isOpen && onClose) {
        onClose();
      }
    }, [isOpen, onClose]);

    useEffect(() => {
      if (shouldClose) {
        setIsOpen(false);
      }
    }, [shouldClose]);

    return (
      <SelectUI.Root
        {...props}
        onOpenChange={handleOnOpenChange}
        onValueChange={handleValueChange}
        value={selectedValue}
        open={isOpen}
      >
        <SelectTrigger
          aria-label={ariaLabel}
          ref={forwardedRef}
          css={css}
          isCombinedWithContent={isCombinedWithContent}
          centeredValue={centeredValue}
          disabled={disabled}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
          }}
        >
          <StyledSelectTriggerContent
            hideLabel={hideLabel}
            centeredValue={centeredValue}
            isValueSelected={!!options && !!value && !!value.length}
          >
            {icon}
            {(!value || !value.length) && placeholder && (
              <StyledSelectUIPlaceholder>
                {placeholder}
              </StyledSelectUIPlaceholder>
            )}
            {options && value && value.length && !hideLabel && (
              <StyledSelectUIValue>{renderValue()}</StyledSelectUIValue>
            )}
          </StyledSelectTriggerContent>
          {!isCombinedWithContent && !hideLabel && !disabled && (
            <StyledSelectIcon className="trigger">
              <ChevronUpIcon width={iconSize} height={iconSize} />
            </StyledSelectIcon>
          )}
        </SelectTrigger>
        <SelectContent
          css={contentCss}
          ref={(dropdownRef) => {
            if (notPreventClickBackdrop) return dropdownRef;
            if (dropdownRef) {
              dropdownRef.addEventListener('touchend', handleTouch);
              dropdownRef.addEventListener('touchstart', handleTouch);

              return dropdownRef;
            }
          }}
          position="popper"
          sideOffset={isCombinedWithContent ? -6 : 8}
          isCustomContentWidth={isCustomContentWidth}
          isCombinedWithContent={isCombinedWithContent}
          isCustomChildren={isCustomChildren}
          asChild={isCustomChildren}
          noContentXPadding={isMobileOrTablet}
        >
          <SelectViewport
            isCombinedWithContent={isCombinedWithContent}
            isNoMaxHeight={isCustomChildren}
            noContentPadding={noContentPadding || isCombinedWithContent}
            withSeparators={isMobileOrTablet}
          >
            {options
              ? options.map(({ value, label, data }, index) => (
                  <Fragment key={index}>
                    <SelectItem
                      key={value}
                      value={value}
                      width={contentWidth}
                      maxWidth={renderOption ? '97%' : 0}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {renderOption ? renderOption(data) : label}
                    </SelectItem>
                    {isMobileOrTablet &&
                      !isCombinedWithContent &&
                      index !== options.length - 1 && (
                        <Separator
                          verticalSpace={0}
                          shrinkOut={3}
                          css={{
                            backgroundColor: noSeparator
                              ? 'transparent'
                              : '$primaryTwo',
                            display: 'list-item',
                          }}
                        />
                      )}
                  </Fragment>
                ))
              : children}
            {footerAction && (
              <Box
                fullWidth
                flexCol
                css={{
                  '@xs_sm': {
                    p: '0 $2',
                  },
                }}
              >
                <Separator
                  css={{ backgroundColor: '$primaryTwo', mt: 0 }}
                  verticalSpace={2}
                  shrinkOut={3}
                />
                <Button fullWidth onClick={footerAction.onClick}>
                  <Text level="14-20" textAlign="center">
                    {footerAction.label}
                  </Text>
                </Button>
              </Box>
            )}
          </SelectViewport>
        </SelectContent>
      </SelectUI.Root>
    );
  },
);
