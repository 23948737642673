import { CSS } from '@stitches/react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ProviderCard } from '~components/atoms/ProviderCard';
import { Text } from '~components/atoms/Typography';
import { GAME_PROVIDERS } from '~constants/providers';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { Provider, ProvidersList as ProvidersListType } from '~types/providers';

const ProviderListWrapper = styled(Box, {
  display: 'grid',
  gridGap: '$4',
  gridTemplateColumns: '1fr 1fr 1fr',
  borderRadius: '$10',
  backgroundColor: '$primaryOne',
  padding: '$3',
  '@xl': {
    padding: '$4',
  },
  '@md': {
    padding: '$2',
  },
  '@xs_sm': {
    padding: '$2',
  },
  '@xs': {
    gridTemplateColumns: '1fr 1fr',
  },
});

interface ProviderListProps {
  providers: ProvidersListType;
  customStyles?: CSS;
  customContainerStyles?: CSS;
  activeProviders?: GAME_PROVIDERS[];
  onProviderClick?: (provider: Provider) => void;
}

export const ProvidersList = ({
  providers,
  customStyles,
  customContainerStyles,
  activeProviders,
  onProviderClick,
}: ProviderListProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  return (
    <Box css={customContainerStyles}>
      {!providers.length ? (
        <Box fullWidth fullHeight flexRow alignCenter justifyCenter>
          <Text
            level={isMobileOrTablet ? '12-20' : '14-24'}
            textAlign={'center'}
            color="textSecondaryOne"
          >
            {localized('casino.noFilterResults')}
          </Text>
        </Box>
      ) : (
        <ProviderListWrapper css={customStyles}>
          {providers.map((provider) => (
            <ProviderCard
              key={provider.id}
              active={activeProviders?.includes(provider.id)}
              onClick={onProviderClick}
              {...provider}
            />
          ))}
        </ProviderListWrapper>
      )}
    </Box>
  );
};
