import React from 'react';
import { COLOR_KEYS } from 'stitches.config';

import {
  BETSLIP_HISTORY_TYPE,
  BetslipHistoryTypeOption,
} from '~components/molecules/Betslip/constants';
import { USER_BETSLIP_HISTORY_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { BETSLIP_DETAIL_STATUS, BETSLIP_STATUS } from '~constants/common';
import { ClockIcon, LostStatusIcon, StatusWinIcon } from '~icons';

export enum BET_SECTIONS_VALUES {
  ALL = 'allSections',
  SPORT = 'sport',
  GAMES = 'games',
  SLOTS = 'slots',
  VIRTUAL = 'virtual',
}

export const getLocalizedBetslipStatusOptions = (
  localized: (key: string, args?: object) => string,
) => {
  return [
    {
      label: localized('betslipHistory.status.all'),
      value: '-1',
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.open'),
      value: BETSLIP_STATUS.OPEN.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.win'),
      value: BETSLIP_STATUS.WIN.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.lost'),
      value: BETSLIP_STATUS.LOST.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.partialWin'),
      value: BETSLIP_STATUS.PARTIAL_WIN.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.partialLost'),
      value: BETSLIP_STATUS.PARTIAL_LOST.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.void'),
      value: BETSLIP_STATUS.VOID.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.cutWin'),
      value: BETSLIP_STATUS.CUT_WIN.toString(),
    },
    {
      label: localized('betslipHistory.betslipHistoryTable.status.cashOut'),
      value: BETSLIP_STATUS.CASH_OUT.toString(),
    },
  ];
};

export const getLocalizedBetslipHistoryTypeOptions = (
  localized: (key: string, args?: object) => string,
): BetslipHistoryTypeOption[] => {
  return [
    {
      value: BETSLIP_HISTORY_TYPE.SPORTS,
      label: localized('betslipHistory.type.sports'),
    },
    {
      value: BETSLIP_HISTORY_TYPE.JACKPOT,
      label: localized('betslipHistory.type.jackpot'),
    },
  ];
};

export type BETSLIP_STATUS_DETAIL_KEY =
  | keyof typeof BETSLIP_DETAIL_STATUS
  | keyof typeof BETSLIP_STATUS;

export const STATUS_CODE_COLORS: Record<BETSLIP_STATUS_DETAIL_KEY, COLOR_KEYS> =
  {
    NOT_RESULTED: 'accentPrimaryTwo',
    LOST: 'redPrimary',
    OPEN: 'accentPrimaryTwo',
    PARTIAL_WIN: 'greenPrimary',
    WIN: 'greenPrimary',
    CUT_WIN: 'greenPrimary',
    PARTIAL_LOST: 'greenPrimary',
    VOID: 'textSecondaryOne',
    REFUND: 'textSecondaryOne',
    HALF_LOST: 'greenPrimary',
    HALF_WON: 'greenPrimary',
    LOSER: 'redPrimary',
    WINNER: 'greenPrimary',
    CASH_OUT: 'greenPrimary',
  };

export const STATUS_ICONS: Record<BETSLIP_STATUS, React.ElementType> = {
  [BETSLIP_STATUS.LOST]: LostStatusIcon,
  [BETSLIP_STATUS.OPEN]: ClockIcon,
  [BETSLIP_STATUS.WIN]: StatusWinIcon,
  [BETSLIP_STATUS.PARTIAL_WIN]: StatusWinIcon,
  [BETSLIP_STATUS.PARTIAL_LOST]: StatusWinIcon,
  [BETSLIP_STATUS.VOID]: StatusWinIcon,
  [BETSLIP_STATUS.CUT_WIN]: StatusWinIcon,
  [BETSLIP_STATUS.CASH_OUT]: StatusWinIcon,
};

export const BETSLIP_HISTORY_TABS_LIST = [
  USER_BETSLIP_HISTORY_TABS.SPORTS,
  USER_BETSLIP_HISTORY_TABS.JACKPOT,
];
