import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks';

interface FiltersMobileButtons {
  hideFilters: () => void;
  showResults: () => void;
  disabled?: boolean;
}

export const StyledText = styled(Text, {
  fontSize: '$14 !important',
  lineHeight: '$24 !important',
  fontWeight: '$medium !important',
  textAlign: 'center !important',
  variants: {
    smallFont: {
      true: {
        fontSize: '$12 !important',
        lineHeight: '$16 !important',
      },
    },
  },
});

export const StyledButton = styled(Button, {
  height: '32px !important',
  flex: 1,
  borderRadius: '$6 !important',
  defaultVariants: {
    size: 'small',
    noBorder: true,
  },
});

export const FiltersMobileButtons = ({
  hideFilters,
  showResults,
  disabled = false,
}: FiltersMobileButtons) => {
  const { localized } = useTranslation();

  return (
    <Box flexRow gap={2}>
      <StyledButton variant="secondary" onClick={hideFilters}>
        <StyledText>{localized('bonuses.button.closeFilters')}</StyledText>
      </StyledButton>
      <StyledButton variant="primary" onClick={showResults} disabled={disabled}>
        <StyledText isButton>{localized('bonuses.button.show')}</StyledText>
      </StyledButton>
    </Box>
  );
};
