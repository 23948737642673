import React, {
  ComponentPropsWithRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { StyledLoadingIcon } from '~components/atoms/Loader';
import { ellipsis } from '~utils/cssUtils';

export interface ButtonPrimitiveProps extends ComponentPropsWithRef<'button'> {
  children: React.ReactNode;
  isLoading?: boolean;
}

const ButtonPrimitive = forwardRef<HTMLButtonElement, ButtonPrimitiveProps>(
  ({ children, isLoading, type, ...rest }, ref) => {
    const localRef = useRef<HTMLButtonElement>(null);
    const [loadingHeight, setLoadingHeight] = useState<string>('0px');

    useEffect(() => {
      const targetRef = ref || localRef;

      if (targetRef && 'current' in targetRef && targetRef.current) {
        const height = targetRef.current.getBoundingClientRect().height;

        setLoadingHeight(height + 'px');
      }
    }, [ref]);

    return (
      <button
        ref={ref || localRef}
        {...rest}
        type={type || 'button'}
        style={{ minHeight: isLoading ? loadingHeight : 'auto' }}
      >
        {isLoading ? (
          <Box as="span" flexRow absoluteCenter css={{ color: '$textButtons' }}>
            <StyledLoadingIcon />
          </Box>
        ) : (
          children
        )}
      </button>
    );
  },
);

export const BasicButton = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithRef<'button'>
>((props, ref) => <button ref={ref} {...props} />);
``;

export const Button = styled(ButtonPrimitive, {
  position: 'relative',
  textAlign: 'center',
  border: 'none',
  cursor: 'pointer',
  textTransform: 'uppercase',
  transition: 'box-shadow 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...ellipsis,
  variants: {
    variant: {
      primary: {
        color: '$textButtons',
        backgroundColor: '$accentPrimaryOne',
        fontSize: '$lg',
        fontWeight: '$bold',
        lineHeight: '$40',
        borderRadius: '$8',
        '&:hover': {
          backgroundColor: '$hoverPrimary !important',
        },
        '&:active': {
          backgroundColor: '$pressedOne !important',
        },
      },
      secondary: {
        color: '$textPrimaryOne',
        backgroundColor: '$secondaryOne',
        fontSize: '$lg',
        fontWeight: '$medium',
        lineHeight: '$40',
        borderRadius: '$8',
        '&:hover': {
          color: '$hoverPrimary !important',
        },
        '&:active': {
          color: '$pressedOne !important',
        },
      },
      red: {
        color: '$redPrimary',
        backgroundColor: '$redSecondary',
        fontSize: '$lg',
        fontWeight: '$medium',
        lineHeight: '$40',
        borderRadius: '$8',
        '&:hover': {
          color: '$hoverPrimary',
        },
        '&:active': {
          color: '$pressedOne',
        },
      },
      transparent: {
        color: '$textButtons',
        backgroundColor: 'transparent',
        fontSize: '$lg',
        fontWeight: '$medium',
        lineHeight: '$40',
      },
    },
    size: {
      large: {
        p: '$1 $3',
        fontSize: '$lg',
      },
      medium: {
        p: '$2 $3',
        fontSize: '$sm',
        lineHeight: '$20',
      },
      small: {
        p: '2px $5',
        fontSize: '$sm',
        lineHeight: '$28',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    disabled: {
      true: {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.4,
      },
    },
    iconButton: {
      true: {
        width: '32px',
        minWidth: '32px',
        lineHeight: 0,
        p: '$2',
        backgroundColor: '$secondaryTwo',
        color: '$textSecondaryOne',
        borderRadius: '$6',
        '&:hover': {
          color: '$hoverPrimary',
        },
        '&:active': {
          color: '$pressedOne',
        },
      },
    },
    noBorder: {
      true: {
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
        '&:hover, &:active': {
          boxShadow: 'none',
        },
      },
    },
    noBackground: {
      true: {
        background: 'none',
      },
    },
  },

  compoundVariants: [],

  defaultVariants: {
    variant: 'primary',
    size: 'large',
  },
});

Button.displayName = 'Button';
