import { CSS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const styledSportSliderItemPseudoCSS: CSS = {
  content: '""',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '1px',
  height: '$4',
  backgroundColor: '$secondaryOne',
  borderRadius: '$4',
};

export const StyledSportSliderItem = styled(Box, {
  position: 'relative',
  minWidth: '80px',
  backgroundColor: '$primaryOne',
  '&:not(:last-child):before': {
    ...styledSportSliderItemPseudoCSS,
    right: 0,
  },
  '&.active:not(:first-child):after': {
    ...styledSportSliderItemPseudoCSS,
    left: '-1px',
  },
  '& > .icon': {
    color: '$textSecondaryOne',
    svg: {
      width: '$5',
      height: '$5',
    },
  },
  '&.active > .icon': {
    color: '$textPrimaryOne',
  },
  '& > .icon > .with-counts': {
    top: '-6px',
    left: '60%',
  },
});

export const styledSportSliderTagCSS: CSS = {
  position: 'absolute',
  fontSize: '$xxxxs',
  lineHeight: '$12',
  color: 'inherit',
  left: '85%',
  top: '0',
  width: 'auto',
  px: '$2',
};
