import { styled } from 'stitches.config';

export const StyledInput = styled('input', {
  fontFamily: 'inherit',
  fontSize: '$18',
  lineHeight: '$24',
  width: '$7',
  height: '$8',
  backgroundColor: '$secondaryTwo',
  textAlign: 'center',
  border: 'none',
  borderRadius: '$6',
  '&::placeholder': {
    color: '$textSecondaryOne',
  },
  '&:focus': {
    border: '1px solid $pressedOne !important',
  },
});
